@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

:root {
  --stoneX-midnight: #040C17;
  --dark-primary-blue: #26486f;
  --dark-secondary-blue: #2371C4;
  --light-green: #CDE8CD;
  --dark-green: #1e553d;
  --light-yellow: #F8F0AE;
  --dark-yellow: #c5b22f;
  --dark-gold: #eca83f;
  --dark-red: #861f22;
  --dark-purple: #442547;
  --dark-white: #f9f9f9;
  --dark-white-border: #c5c7c7;
  --mauve: #686058;
  --green: #61A644;

  --red-number: #EB0000;
  --green-number: #078a04;
  --green-cell-background: #dff4df;
  --red-cell-background: #ffdcdc;

  --gray1: #333333;
  --gray2: #555759;
  --gray3: #77787a;
  --gray4: #b0b3b3;
  --gray5: #cfd3d3;
  --gray6: #e7e9e9;
  --gray7: #e9eef1;
  --gray8: #f3f4f4;
  --gray9: #f9f9f9;

  --disabled: #e7e9e9;
  --error-background: #fcf7f7;
  --light-input-background: $white;
  --dark-input-background: #363636;
  --dark-disabled-input-background: #484848;

  --dark-text-primary: #333333;
  --dark-text-secondary: #555759;
  --light-text-primary: #f5f5f5;
  --light-text-secondary: #b0b3b3;

  --white: #fff;
  --black: #000;
  --disabled-text: #b0b3b3;

  --display-font: 'Crimson Text', 'Times New Roman', serif;
  --font-family: 'Mulish', sans-serif;
}

.bold {
  font-weight: 700;
}

.green {
  color: var(--green-number);
}

.red {
  color: var(--red-number);
}

/* Force auto layout heights to not have min height */
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-viewport
{
  min-height: 0px;
}

.ag-theme-alpine {
    --ag-foreground-color: var(--gray1);
    --ag-background-color: var(--white);
    --ag-odd-row-background-color: var(--gray8);
 
    --ag-header-height: 40px;
    --ag-row-height: 40px; 
    
    --ag-header-foreground-color: var(--gray1);
    --ag-header-background-color: var(--gray9);
    
    --ag-header-column-resize-handle-height: 60%;
    --ag-header-column-resize-handle-width: 1px;
    --ag-header-column-resize-handle-color: var(--gray5);

    --ag-row-border-style: solid;
    --ag-borders: solid 1px;
    --ag-border-color: var(--gray5);
    --ag-selected-row-background-color: inherit !important;

    --ag-row-border-width: 1px;
    --ag-row-border-style: solid;
    --ag-row-border-color: var(--gray5);

    --ag-cell-horizontal-border: solid var(--gray5);
    --ag-cell-vertical-padding: 16px;
    
    --ag-font-size: 13px;
    --ag-font-family: 'Mulish';
}

.ag-header-row {
  height: 40px;
  border-bottom: 1px solid var(--gray5);
}

/* Dont have button borders for parent headers. Add it manually for each cell that doesn't span */
.ag-header-row-column-group { 
  border-bottom: 0px solid var(--gray5);
}

.ag-header-group-cell-with-group {
  border-bottom: 1px solid var(--gray5);
}

.ag-root {
  /* border: 1px solid var(--gray5); */
}

.ag-with-inputs {
    --ag-cell-horizontal-padding: 8px;
}

.ag-row-last
{
  border: 0px solid black;
}

.ag-row-footer {
  border: 0px;
  border-top: 2px solid var(--box-border-color);
  background-color: var(--ag-header-background-color) !important;
}

.ag-row-footer .ag-cell {
  border: 0px
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Center the group cell header labels */
.ag-header-group-cell-with-group {
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.ag-cell {
  display: flex;
  align-items: center;
}

.ag-cell:last-child {
  border: 0px;
}

/* Custom AG-Grid Class */
.ag-row-color-1 {
  background-color: var(--gray8);
}

/* Custom AG-Grid Class */
.ag-row-color-2 {
  background-color: var(--white);
}

/* Custom AG-Grid Class */
.ag-green-cell-background {
  background-color: var(--green-cell-background);
}

/* Custom AG-Grid Class */
.ag-red-cell-background {
  background-color: var(--red-cell-background);
}

.ag-red-cell-text {
  color: var(--red-number);
}

.ag-red-cell-border {
  border: 1px solid var(--red-cell-background);
}

.ag-custom-horizontal-scroll {
  position: fixed;
  bottom: 0;
  height: 16px;
  /* Width is set logically */
  overflow-x: auto;
}

.ag-custom-horizontal-scroll-inner {
  /* Width is set logically */
  height: 1px;
}


body {
  --font-color: var(--gray1);

  --header-height: 60px;
  --header-background-color: var(--white);

  --main-background-color: var(--white);
  --modal-background-color: var(--white);

  --box-divider-color: var(--gray5);

  --button-primary-text-color: var(--white);
  --button-primary-color: var(--dark-secondary-blue);
  --button-primary-hover-text-color: var(--white);
  --button-primary-hover-color: var(--dark-secondary-blue);
  --button-primary-disabled-color: var(--gray6);

  --button-outlined-text-color: var(--gray1);
  --button-outlined-color: var(--white);
  --button-outlined-hover-text-color: var(--dark-secondary-blue);
  --button-outlined-hover-color: var(--white);
  --button-outlined-border-color: var(--gray5);

  --button-textual-text-color: var(--dark-secondary-blue);
  --button-textual-text-hover-color: var(--dark-secondary-blue);
  --button-group-textual-divider-color: var(--gray5);

  --chip-border-color: var(--dark-secondary-blue);
  --chip-color: var(--white);
  --chip-text-color: var(--dark-secondary-blue);
  --chip-delete-icon-color: var(--black);

  --chip-info-text-color: var(--white);
  --chip-info-color: var(--gray4);
  --chip-neutral-text-color: var(--black);
  --chip-neutral-color: var(--light-yellow);
  --chip-success-text-color: var(--black);
  --chip-success-color: var(--light-green);

  --input-disabled-color: var(--gray6);

  --pill-button-border-color: var(--gray5);
  --pill-button-border-hover-color: var(--gray3);
  --pill-active-button-text-color: var(--dark-secondary-blue);
  --pill-active-button-border-color: var(--dark-secondary-blue);

  --textfield-filled-background-color: #283649;
  --textfield-filled-text-color: var(--white);

  --box-border-color: var(--gray5);

  --hover-link-color: var(--gray1);
  --inactive-link-color: var(--gray3);
  --active-link-color: var(--gray1);
  --active-link-border-color: black;

  --toggle-button-group-background-color: var(--gray6);
  --toggle-button-color: var(--gray1);
  --toggle-button-background-color: white;

  --navigation-background-color: var(--stoneX-midnight);
  --navigation-background-hover-color: #425A7C;
  --navigation-background-active-color: #425A7C;
  --navigation-link-color: var(--gray9);
  --navigation-header-color: var(--light-text-secondary);

  --tabs-text-color: var(--gray2);
  --tabs-border-color: var(--gray5);

  --toast-success-background-color: var(--light-green);
  --toast-success-text-color: var(--black);

  --box-outline-color: var(--gray5);
  --helper-text-color: var(--gray3);
}

body {
  margin: 0;
  background-color: var(--header-background-color);
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

h1 {
  font-size: 21px;
  font-weight: 700;
  /* line-height: 1em; */
  letter-spacing: 0em;
  margin-top: 0;
  margin-bottom: 0;
  /* margin-bottom: 20px; */
}

h2 {
  font-size: 18px;
  font-weight: 700;
  /* line-height: 1em; */
  letter-spacing: 0em;
  margin-top: 0;
  margin-bottom: 0;
  /* margin-bottom: 20px; */
}

h3 {
  font-size: 16px;
  font-weight: 700;
  /* line-height: 1em; */
  /* letter-spacing: 0em; */
  margin-top: 0;
  margin-bottom: 0;
  /* margin-bottom: 20px; */
}

/* Used for MTM Metrics at the top */
.metric {
  display: flex;
  flex-direction: row;
  clear: both;
  justify-content: space-between;
}

.metric-label {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 32px;
  text-align: right;
}

.metric-value {
  font-size: 16px;
  font-weight: 700;
}

label.label {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 5px;
}

.justify-right {
  justify-content: right;
}

hr.divider {
  border: 0px;
  border-bottom: 1px solid var(--box-divider-color);
  margin-bottom: 20px;
}

/* THIS IS ADDED SO THE CUSTOM LIST BOX IN THE AUTO COMPLETE COMPONENT CAN HAVE THE NECSSARY STYLES */
.MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true'] {
  background: dodgerblue;
}

/* THIS IS ADDED SO THE CUSTOM LIST BOX IN THE AUTO COMPLETE COMPONENT CAN HAVE THE NECSSARY STYLES */
.MuiAutocomplete-popper .MuiAutocomplete-option[role='option'][aria-selected='false']:hover {
  background: rgba(0, 0, 0, 0.1);
}

.fullscreen-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  z-index: 9999;
  background-color: #fff; /* Optional: Set background color */
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

/* For Highcharts */
.highcharts-toolip {
  padding: 15px
}