@charset "utf-8";

.snx-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.snx-grid {
  display: inline-block;
  position: relative;
  width: 52px;
  height: 52px;
  transform-origin: center;
}
.snx-grid div {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 0;
  background: transparent;
  animation-name: snx-grid, snx-colors;
  animation-duration: 2.7s, 5.4s;
  animation-iteration-count: infinite;
  animation-direction: normal, normal;
  animation-timing-function: ease-in, linear;
}
.snx-grid div:nth-child(1) {
  top: 4px;
  left: 4px;
  animation-delay: 0s;
}
.snx-grid div:nth-child(2) {
  top: 4px;
  left: 16px;
  animation-delay: -0.5s;
}
.snx-grid div:nth-child(3) {
  top: 4px;
  left: 28px;
  animation-delay: -1s;
}
.snx-grid div:nth-child(4) {
  top: 4px;
  left: 40px;
  animation-delay: -1.5s;
}
.snx-grid div:nth-child(5) {
  top: 16px;
  left: 4px;
  animation-delay: -1.5s;
}
.snx-grid div:nth-child(6) {
  top: 16px;
  left: 16px;
  animation-delay: -2s;
}
.snx-grid div:nth-child(7) {
  top: 16px;
  left: 28px;
  animation-delay: -2.5s;
}
.snx-grid div:nth-child(8) {
  top: 16px;
  left: 40px;
  animation-delay: -3s;
}
.snx-grid div:nth-child(9) {
  top: 28px;
  left: 4px;
  animation-delay: -3s;
}
.snx-grid div:nth-child(10) {
  top: 28px;
  left: 16px;
  animation-delay: -2.5s;
}
.snx-grid div:nth-child(11) {
  top: 28px;
  left: 28px;
  animation-delay: -2s;
}
.snx-grid div:nth-child(12) {
  top: 28px;
  left: 40px;
  animation-delay: -1.5s;
}
.snx-grid div:nth-child(13) {
  top: 40px;
  left: 4px;
  animation-delay: -1.5s;
}
.snx-grid div:nth-child(14) {
  top: 40px;
  left: 16px;
  animation-delay: -1s;
}
.snx-grid div:nth-child(15) {
  top: 40px;
  left: 28px;
  animation-delay: -0.5s;
}
.snx-grid div:nth-child(16) {
  top: 40px;
  left: 40px;
  animation-delay: 0s;
}
@keyframes snx-grid {
  0%,
  40%,
  60% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes snx-fade {
  0%,
  100% {
    opacity: 0.2;
  }
  8%,
  92% {
    opacity: 1;
  }
}
@keyframes snx-colors {
  0%,
  25% {
    background: #336094;
  }
  26%,
  50% {
    background: #287151;
  }
  51%,
  75% {
    background: #e0d253;
  }
  76%,
  100% {
    background: #e99c23;
  }
}
