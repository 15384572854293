header {
  height: var(--header-height);
  background-color: var(--header-background-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 20px;
  margin: 0 0px;
  padding: 0 20px;
  border-bottom: 1px solid var(--box-divider-color);
}

header .logo {
  height: 50px;
  /* margin-right: 80px; */
}