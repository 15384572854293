p {
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Top-level ordered list (numbers) */
ol {
    list-style-type: decimal; /* 1, 2, 3, ... */
    margin-left: 20px;
    padding-left: 10px;
    font-size: 16px;
    line-height: 1.6;
}

/* Nested ordered list (letters) */
ol ol {
    list-style-type: lower-alpha; /* a, b, c, ... */
    margin-left: 20px;
    padding-left: 20px;
    font-size: 15px;
}

/* List item styling */
ol li {
    margin-bottom: 10px;
}

/* Nested list item styling */
ol ol li {
    margin-bottom: 5px;
}

/* List marker styling for top-level list */
ol li::marker {
    color: #000;
    font-weight: bold;
}

/* List marker styling for nested list */
ol ol li::marker {
    color: #000;
    font-weight: bold;
}
