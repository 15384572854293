main {
  display: flex;
  flex-grow: 1;
  background-color: var(--main-background-color);
  margin: 20px 20px;
}

main .main-page {
  width: 100%;
  height: 100%;
}