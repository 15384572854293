.note-container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--box-outline-color);
  padding: 16px;
  margin-bottom: 16px;
}

.note-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--box-outline-color);
}

.note-date {
  color: var(--helper-text-color);
}

.note-body {
  display: flex;
  margin-bottom: 8px;
}

.note-input-body > *:not(:last-child) {
  margin-bottom: 16px;
}

.note-input-body > :last-child {
  margin-bottom: 8px;
}

.note-footer {
  display: flex;
  justify-content: right;
}

.note-footer > *:not(:last-child) {
  margin-right: 16px !important;
}

.note-attribution {
  display: flex;
  justify-content: right;
}