aside {
  width: 200px;
  background-color: var(--navigation-background-color);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-shrink: 0;
}

// aside .sidebar-toggle {
//   position: relative; 
//   padding: 0px 20px;
//   height: 35px;
//   line-height: 35px;
// }

// aside .sidebar-toggle svg {
//   height: 1em;
//   width: 1em;
//   vertical-align: middle;
// }

// aside.collapsed {
//   width: auto;
// }

// aside.collapsed .aside-text {
//   display: block; 
//   visibility: hidden; 
//   position: absolute; 
//   white-space: nowrap;
//   left: 50px;
//   top: 0%;
//   background-color: #e9eef1;
//   padding-right: 10px; 
//   border-radius: 0 5px 5px 0;
//   box-sizing: border-box;
//   background-color: #1367c6;
//   color: var(--white);
//   z-index: 1000;
// }

// aside.collapsed .nav-link:hover .aside-text {
//   visibility: visible; /* Make the text visible on hover */  
// }

aside .nav-header {
  position: relative; 
  padding: 0px 20px;
  height: 35px;
  line-height: 35px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  color: var(--navigation-header-color);
}

aside ul {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  padding: 8px 0px;
}

aside .nav-link {
  position: relative; 
  padding: 0px 20px;
  height: 45px;
  line-height: 45px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--navigation-link-color);
}

aside .nav-link.active {
  background-color: var(--navigation-background-active-color);
  color: var(--white);
}

aside a {
  display: block;
  text-decoration: none;
}

li:has(.nav-link):hover {
  background-color: var(--navigation-background-hover-color);
  color: var(--white);
}

aside hr {
  margin: 0px 14px;
}

// aside a > svg {  
//   display: inline-block;
//   vertical-align: middle;
//   height: 1em;
//   width: 1em;
//   padding: 0px 5px 0px 0px;
// }
