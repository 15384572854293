$ribbon-bar-width: calc(100% / 7);

.ribbon {
  width: 100%;
  background-repeat: repeat-x;
  background-size: 700px;
  //   background-image: url("https://mono.dev.corp.stonex.com/images/ribbon.png");
  background-image: repeating-linear-gradient(
    to right,
    var(--primary)-blue 0,
    var(--primary)-blue calc(1 * #{$ribbon-bar-width}),
    var(--secondary)-blue calc(1 * #{$ribbon-bar-width}),
    var(--secondary)-blue calc(2 * #{$ribbon-bar-width}),
    var(--blue) calc(2 * #{$ribbon-bar-width}),
    var(--blue) calc(3 * #{$ribbon-bar-width}),
    var(--green) calc(3 * #{$ribbon-bar-width}),
    var(--green) calc(4 * #{$ribbon-bar-width}),
    var(--yellow) calc(4 * #{$ribbon-bar-width}),
    var(--yellow) calc(5 * #{$ribbon-bar-width}),
    var(--gold) calc(5 * #{$ribbon-bar-width}),
    var(--gold) calc(6 * #{$ribbon-bar-width}),
    var(--red) calc(6 * #{$ribbon-bar-width}),
    var(--red) 100%
  );
}

@media (max-width: 700px) {
  .ribbon {
    background-size: cover;
  }
}
