.tabs-container {
    width: 100%;
    border-bottom: 1px solid var(--tabs-border-color); /* Line across the entire tab system */
    display: flex;
    width: 100%;
    height: 42px;
  }
  
  .tab {
    padding: 12px 16px;
    line-height: 14px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    border: 1px solid transparent;
    position: relative;
    color: var(--tabs-text-color);
    display: inline-flex;
  }
  
  .tab.active {
    border-top: 1px solid var(--tabs-border-color);
    border-left: 1px solid var(--tabs-border-color);
    border-right: 1px solid var(--tabs-border-color);
    border-bottom: none;
    background-color: white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-bottom: -1px; /* Align with the content area */
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid var(--tabs-border-color);
    border-top: none;
    background-color: white;
  }
  